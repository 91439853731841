import styled from "styled-components";
import SeLogo from "../../images/se-logo-blue-transp-bg.svg";

const StyledSeLogo = styled(SeLogo)`
  height: 4em;
  width: 4em;
  justify-self: end;
  grid-column: 4;
`;

export default StyledSeLogo;
